.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@import url('https://fonts.cdnfonts.com/css/depixel');

.paragraph-3{
  color: #fff !important;
}
.cta{
  display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}
.header {
  display: flex;
    align-items: center;
    flex-direction: column;
}
@media screen and (min-width: 1440px) {
  .contact {
    font-size: 20px;
  }

  .body {
    padding-top: 0;
  }

  .heading {
    font-size: 60px;
  }

  .pixel-button-label {
    text-align: center;
  }

  .paragraph-3 {
    margin-top: 7px;
    font-size: 24px;
    line-height: 35px;
  }

  .actions.navigation-button {
    background-color: rgba(0, 0, 0, 0);
  }

  .container.hero-container {
    padding-bottom: 0;
  }

  .contact-signoff.with-shadow {
    text-shadow: 0 2px #000;
  }

  .subheading.subheading-ondarkgreen.timeline-heading {
    text-align: center;
  }

  .header-link {
    font-family: Kemco pixel, sans-serif;
  }

  .team-member-image {
    width: 270px;
    height: 270px;
    max-height: none;
  }

  .team {
    background-color: #622aff;
    background-image: url("https://cdn.prod.website-files.com/62be13fdb8a06d0f7cf4aa7b/62d3682288eb8a7830a5b03e_clean-purple-green.png");
    background-position: 50% 100%;
    background-repeat: repeat-x;
    background-size: 13px;
    margin-top: -50px;
    padding-top: 25px;
    padding-bottom: 256px;
  }

  .team-heading {
    height: 2.5em;
    font-size: 2em;
    font-weight: 700;
  }

  .job-titile {
    text-shadow: 0 2px #000;
  }

  .paragraph-6, .paragraph-7, .paragraph-8 {
    text-shadow: none;
  }

  .milestone-line-top {
    background-color: #222044;
  }

  .section-timeline {
    background-color: #169f00;
  }

  .milestone-line-bottom {
    background-color: #222044;
  }

  .milestone-circle {
    border-width: 4px;
    border-color: #222044;
    background-color: #169f00;
    border-radius: 0;
  }

  .milestone-line {
    background-color: #222044;
  }

  .milestone-inner-circle {
    background-color: #222044;
    border-radius: 0;
  }

  .navigation-link {
    color: #fff;
    font-family: Kemco pixel, sans-serif;
  }

  .navigation {
    background-color: rgba(0, 0, 0, 0);
  }

  .image3 {
    padding: 40px 140px;
    transition: opacity .2s;
    transform: rotateX(0)rotateY(180deg)rotateZ(0);
  }

  .section {
    text-decoration: none;
  }

  .link-2, .link-3, .link-4, .link-5, .link-6, .link-7, .link-8, .link-9, .link-11, .link-12 {
    color: #fff;
    font-size: 40px;
    text-decoration: none;
  }

  .integrations-link {
    font-family: Depixelklein, sans-serif;
    font-size: 20px;
    line-height: 25px;
  }

  .image-11 {
    text-align: left;
  }

  .integrate-button {
    background-color: #ed47a4;
    object-fit: fill;
    flex: 0 auto;
    align-self: auto;
    padding: 30px;
    font-family: Kemco pixel, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }

  .paragraph-3-copy {
    margin-top: 64px;
    padding-left: 0;
    padding-right: 0;
    font-size: 24px;
    line-height: 35px;
  }

  .bold-text-6 {
    font-size: 100px;
  }

  .heading-integrations {
    font-size: 70px;
  }

  .paragraph-3-copy {
    margin-top: 7px;
    font-size: 24px;
    line-height: 35px;
  }

  .heading---rules {
    font-size: 60px;
  }

  .pixel-button-2 {
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 20px;
  }

  .brb---header {
    font-size: 70px;
  }

  .brb---big {
    font-size: 20px;
    line-height: 26px;
  }

  .image-17 {
    padding-bottom: 54px;
  }
}

@media screen and (max-width: 991px) {
  .intro.wen {
    background-image: url("https://cdn.prod.website-files.com/62be13fdb8a06d0f7cf4aa7b/62c35951097bad659d9c0107_tile-intro-bottom2x.png");
    background-position: 50% 100%;
    background-repeat: repeat-x;
    background-size: 13px;
    background-attachment: scroll;
    padding-top: 60px;
    padding-bottom: 140px;
  }

  .intro.wen.what {
    background-image: none;
    padding-bottom: 0;
  }

  .feature, .feature.feature-switch {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .logo {
    margin-top: 60px;
  }

  .container {
    padding: 80px 60px;
  }

  .container.intro-container.wen {
    padding-top: 0;
    padding-bottom: 120px;
  }

  .container.roadmap-container, .container.investors-container {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
  }

  .contact-email {
    padding-bottom: 30px;
  }

  .contact-character {
    padding-bottom: 60px;
  }

  .subheading {
    margin-bottom: 16px;
    font-size: 26px;
    line-height: 30px;
  }

  .feature-character, .feature-character-switch {
    width: 100px;
  }

  .image-2 {
    width: 96px;
  }

  .image-3 {
    width: 48px;
    margin-left: 200px;
  }

  .container-2 {
    max-width: 728px;
  }

  .team-grid {
    grid-column-gap: 40px;
  }

  .team-member-image {
    width: 190px;
    height: 190px;
  }

  .team-heading {
    font-size: 24px;
  }

  .section-timeline {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .logo-link {
    margin-right: 0;
  }

  .navigation-wrapper {
    align-items: center;
    width: auto;
    display: flex;
  }

  .navigation-link {
    width: 100%;
  }

  .navigation-menu {
    color: #393b6a;
    background-color: #fff;
    border-radius: 8px;
    padding: 12px;
    position: absolute;
  }

  .navigation-menu.w--open {
    background-color: #282556;
    border-color: #fff;
  }

  .menu-button.w--open {
    background-color: #ea7462;
  }

  .navigation {
    position: relative;
  }

  .nav-menu {
    float: left;
  }

  .navbar {
    background-color: rgba(0, 0, 0, 0);
  }

  .navbar-wrapper-three {
    justify-content: space-between;
  }

  .navbar-brand-three {
    text-align: left;
    width: 325px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0%;
    bottom: auto;
    left: auto;
    right: auto;
  }

  .nav-menu-wrapper-three {
    background-color: rgba(0, 0, 0, 0);
    top: 70px;
  }

  .nav-menu-three {
    background-color: #fff;
    border-radius: 50px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    box-shadow: 0 8px 50px rgba(0, 0, 0, .05);
  }

  .nav-link-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: absolute;
  }

  .menu-button-3 {
    padding: 12px;
  }

  .menu-button-3.w--open {
    color: #fff;
    background-color: #a6b1bf;
  }

  .image-9 {
    margin-top: 0;
  }

  .icon {
    color: #fff;
  }

  .image2 {
    height: 256px;
  }

  .list-3 {
    margin-bottom: 0;
    padding-left: 0;
  }

  .grid {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    justify-content: stretch;
    display: grid;
  }

  .integrations-link {
    font-family: Depixelklein, sans-serif;
    font-size: 14px;
  }

  .integrate-button {
    text-align: center;
  }

  .bold-text-5 {
    font-size: 50px;
    line-height: 20px;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .feature-grid, .feature-grid-2 {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .header {
    padding-top: 24px;
  }

  .menu {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    top: 38px;
    left: 24px;
  }

  .feature-text {
    text-align: center;
    align-items: center;
    max-width: none;
  }

  .feature {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    flex-direction: column;
    align-items: stretch;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .feature.feature-switch {
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .heading {
    text-shadow: 0 4px 0 #28248c;
    font-size: 40px;
    line-height: 50px;
  }

  .list.investor-logos {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  .pixel-button-label {
    font-size: 16px;
    line-height: 16px;
  }

  .heading-5 {
    font-size: 24px;
  }

  .own-text {
    text-align: center;
    align-items: center;
  }

  .logo {
    margin-top: 84px;
  }

  .actions {
    top: 24px;
    right: 24px;
  }

  .container {
    padding: 80px 30px;
  }

  .container.intro-container {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    flex-direction: column;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  .container.roadmap-container, .container.investors-container {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
  }

  .container.own-container {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    flex-direction: column;
    align-items: center;
  }

  .container.hero-container {
    padding: 60px 20px 0;
  }

  .contact-email {
    padding-bottom: 20px;
    font-size: 20px;
    line-height: 20px;
  }

  .contact-character {
    padding-bottom: 40px;
  }

  .contact-signoff {
    font-size: 16px;
    line-height: 18px;
  }

  .intro-text {
    text-align: center;
    align-items: center;
  }

  .subheading {
    text-align: center;
    text-shadow: 0 2px 0 #222044;
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 28px;
  }

  .subheading.subheading-onpurple {
    text-align: center;
  }

  .box-heading {
    font-size: 16px;
    line-height: 18px;
  }

  .heading-13 {
    font-size: 16px;
  }

  .feature-character {
    transform: translate(25%, -50%)rotate(14deg);
  }

  .feature-character-switch {
    transform: translate(-25%, -50%)rotate(-8deg);
  }

  .ticker-text {
    font-size: 16px;
    line-height: 16px;
  }

  .ticker-item {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ticker-icon {
    max-width: 40px;
    max-height: 40px;
  }

  .ticker-list {
    height: 64px;
  }

  .cloud-1.cloud-1-1 {
    display: none;
  }

  .cloud-2 {
    display: block;
  }

  .cloud-2.cloud-2-1 {
    top: 175px;
    left: auto;
    right: 80vw;
  }

  .cloud-2.cloud-2-2 {
    display: none;
  }

  .menu-item {
    font-size: 12px;
    line-height: 12px;
  }

  .team-circles {
    padding: 60px 15px;
  }

  .team-grid {
    grid-template-columns: 1fr 1fr;
  }

  .title-wrapper {
    margin-bottom: 60px;
  }

  .block-empty {
    display: none;
  }

  .milestone-block {
    display: flex;
  }

  .timeline-row {
    grid-template-columns: minmax(20px, 60px) 1fr;
  }

  .card-timeline-large {
    margin-bottom: 32px;
  }

  .navigation-secondary {
    display: none;
  }

  .navbar-brand-three {
    padding-left: 0;
  }

  .nav-menu-three {
    border-radius: 20px;
    flex-direction: column;
    padding-bottom: 30px;
  }

  .nav-menu-block {
    flex-direction: column;
  }

  .nav-link-5 {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .nav-dropdown {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .nav-dropdown-toggle {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .nav-dropdown-list.shadow-three {
    box-shadow: 0 8px 50px rgba(0, 0, 0, .05);
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: relative;
  }

  .nav-dropdown-list.shadow-three.mobile-shadow-hide {
    box-shadow: none;
  }

  .nav-link-accent {
    margin-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .mobile-margin-top-10 {
    margin-top: 10px;
  }

  .link-2, .link-3, .link-4, .link-5, .link-6, .link-7, .link-8, .link-9, .link-10, .link-11 {
    font-size: 30px;
  }

  .grid {
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 20px;
    margin-right: 20px;
  }

  .integrations-link {
    font-size: 15px;
  }

  .integrate-button {
    text-align: center;
    font-size: 16px;
    line-height: 16px;
  }

  .paragraph-3-copy {
    padding-left: 6px;
    padding-right: 6px;
  }

  .bold-text-5 {
    font-size: 40px;
  }

  .heading-integrations {
    text-shadow: 0 4px 0 #28248c;
    font-size: 40px;
    line-height: 50px;
  }

  .container-5 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .heading---rules {
    text-shadow: 0 4px 0 #28248c;
    font-size: 40px;
    line-height: 50px;
  }

  .bold-text-16 {
    font-size: 40px;
  }

  .container-6 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .text-size-large {
    font-size: 1.125rem;
  }

  .layout70_component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .button-link {
    font-size: 1rem;
  }

  .button-link:hover {
    color: #6941c6;
  }

  .page-padding {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .button-row {
    align-self: stretch;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .italic-text {
    text-align: center;
  }

  .brb---header {
    font-size: 40px;
  }

  .feature-grid {
    grid-row-gap: 22px;
    grid-template-columns: 1fr;
  }

  .feature-card-d {
    min-height: auto;
  }

  .section-regular {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .feature-button-group {
    flex-direction: column;
  }

  .h2-heading {
    font-size: 44px;
  }

  .feature-card-svg {
    display: none;
  }

  .feature-grid-2 {
    grid-row-gap: 22px;
    grid-template-columns: 1fr;
  }

  .container-7 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 479px) {
  .footer {
    align-items: flex-start;
  }

  .heading {
    font-size: 20px;
    line-height: 40px;
  }

  .list.investor-logos {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .footer-menu {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    flex-direction: column;
    align-items: flex-start;
  }

  .logo {
    width: 260px;
  }

  .container {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .container.intro-container, .container.roadmap-container {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  .container.investors-container {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    padding-top: 31px;
  }

  .container.own-container {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .container.hero-container {
    padding-top: 40px;
    padding-bottom: 0;
    top: -50px;
  }

  .container.footer-container {
    align-items: flex-start;
  }

  .contact-email {
    font-size: 16px;
    line-height: 16px;
  }

  .cloud-2.cloud-2-1 {
    top: 160px;
  }

  .image-3 {
    display: none;
  }

  .image-6 {
    overflow: visible;
  }

  .image-8 {
    display: none;
  }

  .team-circles {
    background-color: #622aff;
    background-image: url("https://cdn.prod.website-files.com/62be13fdb8a06d0f7cf4aa7b/62d364df5341b34c46b04cfa_62c35c1806edba8b80331fda_tile-footer-top%402x.png");
    background-position: 50% 100%;
    background-repeat: repeat-x;
    background-size: 13px;
    background-attachment: scroll;
  }

  .container-2 {
    max-width: none;
  }

  .centered-heading {
    margin-bottom: 24px;
  }

  .team-grid {
    grid-template-columns: 1fr;
  }

  .milestone-block {
    display: none;
  }

  .timeline-row {
    grid-template-columns: 1fr;
  }

  .section-timeline {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .button-round-light-blue {
    text-align: center;
    margin-right: 0;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 14px;
  }

  .h3-heading {
    font-size: 48px;
  }

  .navigation-secondary, .navigation-button {
    display: none;
  }

  .container-4 {
    background-color: #622aff;
    color: #fff;
  }

  .nav-link {
    background-color: #28248c;
    color: #e3ff37;
    font-family: Kemco pixel, sans-serif;
  }

  .nav-link-2, .nav-link-3, .nav-link-4 {
    background-color: #28248c;
    color: #fff;
    font-family: Kemco pixel, sans-serif;
  }

  .menu-button-2, .menu-button-2.w--open {
    background-color: #622aff;
  }

  .navbar-logo-center-container.shadow-three {
    padding: 24px;
  }

  .nav-menu-three {
    flex-direction: column;
  }

  .menu-button-3 {
    padding: 0;
  }

  .image-9 {
    width: 100%;
    max-width: 250px;
    display: block;
  }

  .image3 {
    padding: 0;
  }

  .image2 {
    margin-bottom: 10px;
  }

  .section {
    background-color: #622aff;
    padding-bottom: 25px;
  }

  .list-3 {
    margin-bottom: 0;
    padding-left: 0;
  }

  .link-2, .link-3, .link-4, .link-5, .link-6, .link-7, .link-8, .link-9, .link-10, .link-11 {
    color: #fff;
    text-decoration: none;
  }

  .list-item-3 {
    line-height: 50px;
  }

  .grid {
    grid-template-columns: 1fr 1fr;
    margin-left: 20px;
    margin-right: 20px;
  }

  .integrate-button {
    text-align: center;
    align-self: center;
  }

  .heading-integrations {
    font-size: 30px;
    line-height: 40px;
  }

  .integrations-image {
    float: none;
    clear: none;
    text-align: center;
    object-fit: fill;
    display: block;
    position: static;
  }

  .link-block, .integrations-pic-link {
    text-align: center;
    object-fit: fill;
  }

  .container-5 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .heading---rules {
    font-size: 20px;
    line-height: 40px;
  }

  .bold-text-16 {
    font-size: 30px;
  }

  .container-6 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .image-13 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .brb---header {
    font-size: 30px;
  }

  .button-l-icon {
    flex: none;
  }

  .image-17 {
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .container-7 {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.cover{
  background-color: #622aff;
  width: 100%;
  padding-bottom: 0;
  position: relative;
  overflow: hidden;
  height: 100vh;
}


.cloud-1.cloud-1-1 {
  top: -50px;
  left: 30vw;
}

.cloud-1.cloud-1-2 {
  top: -30px;
  left: 170vw;
}

.cloud-2 {
  position: absolute;
  top: 300px;
  left: 95vw;
}

.cloud-2.cloud-2-1 {
  top: 200px;
  left: 2vw;
}

.cloud-2.cloud-2-3 {
  top: 250px;
  left: 160vw;
}

.cloud-3 {
  position: absolute;
  top: 500px;
  left: 50vw;
}

.cloud-3.cloud-3-1 {
  top: 590px;
  left: 75vw;
}

.cloud-3.cloud-3-2 {
  top: 590px;
  left: 150vw;
}

.investors-text {
  max-width: 500px;
}

.paragraph-4 {
  text-align: center;
}

.paragraph-4.platform {
  color: #222044;
  font-size: 18px;
}

.paragraph-5 {
  line-height: 22px;
}

.image-2 {
  width: auto;
  height: 96px;
  margin-bottom: 24px;
}
@media screen and (min-width: 1440px) {
  .contact {
    font-size: 20px;
  }

  .body {
    padding-top: 0;
  }

  .heading {
    font-size: 60px;
  }

  .pixel-button-label {
    text-align: center;
    display: inline-block;
    position: relative;
    padding: 16px 16px;
    background-color: #E3FF37;
    color: #28248C;
    clip-path: polygon(0 4px, 4px 4px, 4px 0, calc(100% - 4px) 0, calc(100% - 4px) 4px, 100% 4px, 100% calc(100% - 4px), calc(100% - 4px) calc(100% - 4px), calc(100% - 4px) 100%, 4px 100%, 4px calc(100% - 4px), 0 calc(100% - 4px));
    box-shadow: inset -4px -4px 0px rgba(0, 0, 0, 0.2), inset 4px 4px 0px rgba(255, 255, 255, 0.2);
  }

  .paragraph-3 {
    margin-top: 7px;
    font-size: 24px;
    line-height: 35px;
  }

  .actions.navigation-button {
    background-color: rgba(0, 0, 0, 0);
  }

  .container.hero-container {
    padding-bottom: 0;
  }

  .contact-signoff.with-shadow {
    text-shadow: 0 2px #000;
  }

  .subheading.subheading-ondarkgreen.timeline-heading {
    text-align: center;
  }

  .header-link {
    font-family: Kemco pixel, sans-serif;
  }

  .team-member-image {
    width: 270px;
    height: 270px;
    max-height: none;
  }

  .team {
    background-color: #622aff;
    background-image: url("https://cdn.prod.website-files.com/62be13fdb8a06d0f7cf4aa7b/62d3682288eb8a7830a5b03e_clean-purple-green.png");
    background-position: 50% 100%;
    background-repeat: repeat-x;
    background-size: 13px;
    margin-top: -50px;
    padding-top: 25px;
    padding-bottom: 256px;
  }

  .team-heading {
    height: 2.5em;
    font-size: 2em;
    font-weight: 700;
  }

  .job-titile {
    text-shadow: 0 2px #000;
  }

  .paragraph-6, .paragraph-7, .paragraph-8 {
    text-shadow: none;
  }

  .milestone-line-top {
    background-color: #222044;
  }

  .section-timeline {
    background-color: #169f00;
  }

  .milestone-line-bottom {
    background-color: #222044;
  }

  .milestone-circle {
    border-width: 4px;
    border-color: #222044;
    background-color: #169f00;
    border-radius: 0;
  }

  .milestone-line {
    background-color: #222044;
  }

  .milestone-inner-circle {
    background-color: #222044;
    border-radius: 0;
  }

  .navigation-link {
    color: #fff;
    font-family: Kemco pixel, sans-serif;
  }

  .navigation {
    background-color: rgba(0, 0, 0, 0);
  }

  .image3 {
    padding: 40px 140px;
    transition: opacity .2s;
    transform: rotateX(0)rotateY(180deg)rotateZ(0);
  }

  .section {
    text-decoration: none;
  }

  .link-2, .link-3, .link-4, .link-5, .link-6, .link-7, .link-8, .link-9, .link-11, .link-12 {
    color: #fff;
    font-size: 40px;
    text-decoration: none;
  }

  .integrations-link {
    font-family: 'DePixel', sans-serif;

    font-size: 20px;
    line-height: 25px;
  }

  .image-11 {
    text-align: left;
  }

  .integrate-button {
    background-color: #ed47a4;
    object-fit: fill;
    flex: 0 auto;
    align-self: auto;
    padding: 30px;
    font-family: Kemco pixel, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }

  .paragraph-3-copy {
    margin-top: 64px;
    padding-left: 0;
    padding-right: 0;
    font-size: 24px;
    line-height: 35px;
  }

  .bold-text-6 {
    font-size: 100px;
  }

  .heading-integrations {
    font-size: 70px;
  }

  .paragraph-3-copy {
    margin-top: 7px;
    font-size: 24px;
    line-height: 35px;
  }

  .heading---rules {
    font-size: 60px;
  }

  .pixel-button-2 {
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 20px;
  }

  .brb---header {
    font-size: 70px;
  }

  .brb---big {
    font-size: 20px;
    line-height: 26px;
  }

  .image-17 {
    padding-bottom: 54px;
  }
}

@media screen and (max-width: 991px) {
  .intro.wen {
    background-image: url("https://cdn.prod.website-files.com/62be13fdb8a06d0f7cf4aa7b/62c35951097bad659d9c0107_tile-intro-bottom2x.png");
    background-position: 50% 100%;
    background-repeat: repeat-x;
    background-size: 13px;
    background-attachment: scroll;
    padding-top: 60px;
    padding-bottom: 140px;
  }

  .intro.wen.what {
    background-image: none;
    padding-bottom: 0;
  }

  .feature, .feature.feature-switch {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .logo {
    margin-right: auto;
    margin-left: auto;
  }

  .container {
    padding: 80px 60px;
  }

  .container.intro-container.wen {
    padding-top: 0;
    padding-bottom: 120px;
  }

  .container.roadmap-container, .container.investors-container {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
  }

  .contact-email {
    padding-bottom: 30px;
  }

  .contact-character {
    padding-bottom: 60px;
  }

  .subheading {
    margin-bottom: 16px;
    font-size: 26px;
    line-height: 30px;
  }

  .feature-character, .feature-character-switch {
    width: 100px;
  }

  .image-2 {
    width: 96px;
  }

  .image-3 {
    width: 48px;
    margin-left: 200px;
  }

  .container-2 {
    max-width: 728px;
  }

  .team-grid {
    grid-column-gap: 40px;
  }

  .team-member-image {
    width: 190px;
    height: 190px;
  }

  .team-heading {
    font-size: 24px;
  }

  .section-timeline {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .logo-link {
    margin-right: 0;
  }

  .navigation-wrapper {
    align-items: center;
    width: auto;
    display: flex;
  }

  .navigation-link {
    width: 100%;
  }

  .navigation-menu {
    color: #393b6a;
    background-color: #fff;
    border-radius: 8px;
    padding: 12px;
    position: absolute;
  }

  .navigation-menu.w--open {
    background-color: #282556;
    border-color: #fff;
  }

  .menu-button.w--open {
    background-color: #ea7462;
  }

  .navigation {
    position: relative;
  }

  .nav-menu {
    float: left;
  }

  .navbar {
    background-color: rgba(0, 0, 0, 0);
  }

  .navbar-wrapper-three {
    justify-content: space-between;
  }

  .navbar-brand-three {
    text-align: left;
    width: 325px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0%;
    bottom: auto;
    left: auto;
    right: auto;
  }

  .nav-menu-wrapper-three {
    background-color: rgba(0, 0, 0, 0);
    top: 70px;
  }

  .nav-menu-three {
    background-color: #fff;
    border-radius: 50px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    box-shadow: 0 8px 50px rgba(0, 0, 0, .05);
  }

  .nav-link-5 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: absolute;
  }

  .menu-button-3 {
    padding: 12px;
  }

  .menu-button-3.w--open {
    color: #fff;
    background-color: #a6b1bf;
  }

  .image-9 {
    margin-top: 0;
  }

  .icon {
    color: #fff;
  }

  .image2 {
    height: 256px;
  }

  .list-3 {
    margin-bottom: 0;
    padding-left: 0;
  }

  .grid {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    justify-content: stretch;
    display: grid;
  }

  .integrations-link {
    font-family: 'DePixel', sans-serif;
    font-size: 14px;
  }

  .integrate-button {
    text-align: center;
  }

  .bold-text-5 {
    font-size: 50px;
    line-height: 20px;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .feature-grid, .feature-grid-2 {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .header {
    padding-top: 24px;
  }

  .menu {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    top: 38px;
    left: 24px;
  }

  .feature-text {
    text-align: center;
    align-items: center;
    max-width: none;
  }

  .feature {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    flex-direction: column;
    align-items: stretch;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .feature.feature-switch {
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .heading {
    text-shadow: 0 4px 0 #28248c;
    font-size: 40px;
    line-height: 50px;
  }

  .list.investor-logos {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  .pixel-button-label {
    font-size: 16px;
    line-height: 16px;
    display: inline-block;
    position: relative;
    padding: 16px 16px;
    background-color: #E3FF37;
    color: #28248C;
    clip-path: polygon(0 4px, 4px 4px, 4px 0, calc(100% - 4px) 0, calc(100% - 4px) 4px, 100% 4px, 100% calc(100% - 4px), calc(100% - 4px) calc(100% - 4px), calc(100% - 4px) 100%, 4px 100%, 4px calc(100% - 4px), 0 calc(100% - 4px));
    box-shadow: inset -4px -4px 0px rgba(0, 0, 0, 0.2), inset 4px 4px 0px rgba(255, 255, 255, 0.2);
    
  }

  .heading-5 {
    font-size: 24px;
  }

  .own-text {
    text-align: center;
    align-items: center;
  }

  .logo {
    margin-top: 84px;
  }

  .actions {
    top: 24px;
    right: 24px;
  }

  .container {
    padding: 80px 30px;
  }

  .container.intro-container {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    flex-direction: column;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  .container.roadmap-container, .container.investors-container {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
  }

  .container.own-container {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    flex-direction: column;
    align-items: center;
  }

  .container.hero-container {
    padding: 60px 20px 0;
  }

  .contact-email {
    padding-bottom: 20px;
    font-size: 20px;
    line-height: 20px;
  }

  .contact-character {
    padding-bottom: 40px;
  }

  .contact-signoff {
    font-size: 16px;
    line-height: 18px;
  }

  .intro-text {
    text-align: center;
    align-items: center;
  }

  .subheading {
    text-align: center;
    text-shadow: 0 2px 0 #222044;
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 28px;
  }

  .subheading.subheading-onpurple {
    text-align: center;
  }

  .box-heading {
    font-size: 16px;
    line-height: 18px;
  }

  .heading-13 {
    font-size: 16px;
  }

  .feature-character {
    transform: translate(25%, -50%)rotate(14deg);
  }

  .feature-character-switch {
    transform: translate(-25%, -50%)rotate(-8deg);
  }

  .ticker-text {
    font-size: 16px;
    line-height: 16px;
  }

  .ticker-item {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ticker-icon {
    max-width: 40px;
    max-height: 40px;
  }

  .ticker-list {
    height: 64px;
  }

  .cloud-1.cloud-1-1 {
    display: none;
  }

  .cloud-2 {
    display: block;
  }

  .cloud-2.cloud-2-1 {
    top: 175px;
    left: auto;
    right: 80vw;
  }

  .cloud-2.cloud-2-2 {
    display: none;
  }

  .menu-item {
    font-size: 12px;
    line-height: 12px;
  }

  .team-circles {
    padding: 60px 15px;
  }

  .team-grid {
    grid-template-columns: 1fr 1fr;
  }

  .title-wrapper {
    margin-bottom: 60px;
  }

  .block-empty {
    display: none;
  }

  .milestone-block {
    display: flex;
  }

  .timeline-row {
    grid-template-columns: minmax(20px, 60px) 1fr;
  }

  .card-timeline-large {
    margin-bottom: 32px;
  }

  .navigation-secondary {
    display: none;
  }

  .navbar-brand-three {
    padding-left: 0;
  }

  .nav-menu-three {
    border-radius: 20px;
    flex-direction: column;
    padding-bottom: 30px;
  }

  .nav-menu-block {
    flex-direction: column;
  }

  .nav-link-5 {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .nav-dropdown {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .nav-dropdown-toggle {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .nav-dropdown-list.shadow-three {
    box-shadow: 0 8px 50px rgba(0, 0, 0, .05);
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: relative;
  }

  .nav-dropdown-list.shadow-three.mobile-shadow-hide {
    box-shadow: none;
  }

  .nav-link-accent {
    margin-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .mobile-margin-top-10 {
    margin-top: 10px;
  }

  .link-2, .link-3, .link-4, .link-5, .link-6, .link-7, .link-8, .link-9, .link-10, .link-11 {
    font-size: 30px;
  }

  .grid {
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 20px;
    margin-right: 20px;
  }

  .integrations-link {
    font-size: 15px;
  }

  .integrate-button {
    text-align: center;
    font-size: 16px;
    line-height: 16px;
  }

  .paragraph-3-copy {
    padding-left: 6px;
    padding-right: 6px;
  }

  .bold-text-5 {
    font-size: 40px;
  }

  .heading-integrations {
    text-shadow: 0 4px 0 #28248c;
    font-size: 40px;
    line-height: 50px;
  }

  .container-5 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .heading---rules {
    text-shadow: 0 4px 0 #28248c;
    font-size: 40px;
    line-height: 50px;
  }

  .bold-text-16 {
    font-size: 40px;
  }

  .container-6 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .text-size-large {
    font-size: 1.125rem;
  }

  .layout70_component {
    grid-row-gap: 3rem;
    grid-template-columns: 1fr;
  }

  .button-link {
    font-size: 1rem;
  }

  .button-link:hover {
    color: #6941c6;
  }

  .page-padding {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .button-row {
    align-self: stretch;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .italic-text {
    text-align: center;
  }

  .brb---header {
    font-size: 40px;
  }

  .feature-grid {
    grid-row-gap: 22px;
    grid-template-columns: 1fr;
  }

  .feature-card-d {
    min-height: auto;
  }

  .section-regular {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .feature-button-group {
    flex-direction: column;
  }

  .h2-heading {
    font-size: 44px;
  }

  .feature-card-svg {
    display: none;
  }

  .feature-grid-2 {
    grid-row-gap: 22px;
    grid-template-columns: 1fr;
  }

  .container-7 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 479px) {
  .footer {
    align-items: flex-start;
  }

  .heading {
    font-size: 20px;
    line-height: 40px;
  }

  .list.investor-logos {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .footer-menu {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    flex-direction: column;
    align-items: flex-start;
  }

  .logo {
    width: 260px;
  }

  .container {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .container.intro-container, .container.roadmap-container {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  .container.investors-container {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    padding-top: 31px;
  }

  .container.own-container {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .container.hero-container {
    padding-top: 40px;
    padding-bottom: 0;
    top: -50px;
  }

  .container.footer-container {
    align-items: flex-start;
  }

  .contact-email {
    font-size: 16px;
    line-height: 16px;
  }

  .cloud-2.cloud-2-1 {
    top: 160px;
  }

  .image-3 {
    display: none;
  }

  .image-6 {
    overflow: visible;
  }

  .image-8 {
    display: none;
  }

  .team-circles {
    background-color: #622aff;
    background-image: url("https://cdn.prod.website-files.com/62be13fdb8a06d0f7cf4aa7b/62d364df5341b34c46b04cfa_62c35c1806edba8b80331fda_tile-footer-top%402x.png");
    background-position: 50% 100%;
    background-repeat: repeat-x;
    background-size: 13px;
    background-attachment: scroll;
  }

  .container-2 {
    max-width: none;
  }

  .centered-heading {
    margin-bottom: 24px;
  }

  .team-grid {
    grid-template-columns: 1fr;
  }

  .milestone-block {
    display: none;
  }

  .timeline-row {
    grid-template-columns: 1fr;
  }

  .section-timeline {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .button-round-light-blue {
    text-align: center;
    margin-right: 0;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 14px;
  }

  .h3-heading {
    font-size: 48px;
  }

  .navigation-secondary, .navigation-button {
    display: none;
  }

  .container-4 {
    background-color: #622aff;
    color: #fff;
  }

  .nav-link {
    background-color: #28248c;
    color: #e3ff37;
    font-family: Kemco pixel, sans-serif;
  }

  .nav-link-2, .nav-link-3, .nav-link-4 {
    background-color: #28248c;
    color: #fff;
    font-family: Kemco pixel, sans-serif;
  }

  .menu-button-2, .menu-button-2.w--open {
    background-color: #622aff;
  }

  .navbar-logo-center-container.shadow-three {
    padding: 24px;
  }

  .nav-menu-three {
    flex-direction: column;
  }

  .menu-button-3 {
    padding: 0;
  }

  .image-9 {
    width: 100%;
    max-width: 250px;
    display: block;
  }

  .image3 {
    padding: 0;
  }
  .pixel-button {
    text-decoration: none;
    text-transform: uppercase;
    filter: /* drop shadow */ drop-shadow(4px 4px 0px rgba(0, 0, 0, 0.25));
}
.pixel-button-label {
  display: inline-block;
  position: relative;
  padding: 16px 16px;
  background-color: #E3FF37;
  color: #28248C;
  clip-path: polygon(0 4px, 4px 4px, 4px 0, calc(100% - 4px) 0, calc(100% - 4px) 4px, 100% 4px, 100% calc(100% - 4px), calc(100% - 4px) calc(100% - 4px), calc(100% - 4px) 100%, 4px 100%, 4px calc(100% - 4px), 0 calc(100% - 4px));
  box-shadow: inset -4px -4px 0px rgba(0, 0, 0, 0.2), inset 4px 4px 0px rgba(255, 255, 255, 0.2);
}
.pixel-button:hover {
    background-color: #28248C;
    color: #E3FF37;
}

.pixel-button > strong {
    display: inline-block;
    position: relative;
    padding: 16px 16px;
    background-color: #E3FF37;
    color: #28248C;
    clip-path: polygon( /* top left */ 0 4px, 4px 4px, 4px 0, /* top right */ calc(100% - 4px) 0, calc(100% - 4px) 4px, 100% 4px, /* bottom right */ 100% calc(100% - 4px), calc(100% - 4px) calc(100% - 4px), calc(100% - 4px) 100%, /* bottom left */ 4px 100%, 4px calc(100% - 4px), 0 calc(100% - 4px) );
    box-shadow: /* bottom right */ inset -4px -4px 0px rgba(0, 0, 0, 0.2), /* top left */ inset 4px 4px 0px rgba(255, 255, 255, 0.2);
}
  .image2 {
    margin-bottom: 10px;
  }

  .section {
    background-color: #622aff;
    padding-bottom: 25px;
  }

  .list-3 {
    margin-bottom: 0;
    padding-left: 0;
  }

  .link-2, .link-3, .link-4, .link-5, .link-6, .link-7, .link-8, .link-9, .link-10, .link-11 {
    color: #fff;
    text-decoration: none;
  }

  .list-item-3 {
    line-height: 50px;
  }

  .grid {
    grid-template-columns: 1fr 1fr;
    margin-left: 20px;
    margin-right: 20px;
  }

  .integrate-button {
    text-align: center;
    align-self: center;
  }

  .heading-integrations {
    font-size: 30px;
    line-height: 40px;
  }

  .integrations-image {
    float: none;
    clear: none;
    text-align: center;
    object-fit: fill;
    display: block;
    position: static;
  }

  .link-block, .integrations-pic-link {
    text-align: center;
    object-fit: fill;
  }

  .container-5 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .heading---rules {
    font-size: 20px;
    line-height: 40px;
  }

  .bold-text-16 {
    font-size: 30px;
  }

  .container-6 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .actions {
    z-index: 250;
    position: fixed;
    top: 54px;
    right: 40px;
  }
  
  .actions-play {
    position: fixed;
    top: 0%;
    bottom: auto;
    left: auto;
    right: 0%;
  }
  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .image-13 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .brb---header {
    font-size: 30px;
  }

  .button-l-icon {
    flex: none;
  }

  .image-17 {
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .container-7 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.pixel-button {
  font-family: Kemco pixel, sans-serif;
  font-style: normal;
  font-weight: 700;
  text-decoration: underline;
  display: block;
}

.pixel-button.actions-play {
  position: fixed;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}

.pixel-button.roadmap-button {
  display: none;
}

.pixel-button.button-hero {
  margin-left: 10px;
  margin-right: 10px;
}

.pixel-box-inner {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pixel-box {
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
}

.pixel-box.pixel-box-purple {
  background-color: #622aff;
}

.pixel-box.pixel-box-blue {
  background-color: #222044;
}

.pixel-box.pixel-box-pink {
  background-color: #ed47a4;
}

.pixel-button-label {
  display: inline-block;
  position: relative;
  padding: 16px 16px;
  background-color: #E3FF37;
  color: #28248C;
  clip-path: polygon(0 4px, 4px 4px, 4px 0, calc(100% - 4px) 0, calc(100% - 4px) 4px, 100% 4px, 100% calc(100% - 4px), calc(100% - 4px) calc(100% - 4px), calc(100% - 4px) 100%, 4px 100%, 4px calc(100% - 4px), 0 calc(100% - 4px));
  box-shadow: inset -4px -4px 0px rgba(0, 0, 0, 0.2), inset 4px 4px 0px rgba(255, 255, 255, 0.2);
}

.paragraph-3 {
  
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
    margin-bottom: 10px;
    font-family: 'DePixel', sans-serif;
  }

.heading{
  color:#ffffff;
  text-align: center;
  text-shadow: 0 8px 0 #28248c;
  margin-bottom: 24px;
  font-family: Kemco pixel, sans-serif;
  font-size: 80px;
  line-height: 80px;
}
.menu {
  float: none;
  clear: none;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  color: #ffffff;
  font-family: Kemco pixel, sans-serif;
  list-style-type: none;
  display: flex;
  position: absolute;
  top: 54px;
  bottom: auto;
  left: 40px;
  right: auto;
}
.w-list-unstyled {
  padding-left: 0;
  list-style: none;
}

@keyframes ticker {
  0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
  }

  100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
  }
}
@import url('https://fonts.cdnfonts.com/css/kemco-pixel');

@keyframes cloud {
  0% {
      transform: translate3d(0, 0, 0);
  }

  49% {
      transform: translate3d(-200vw, 0, 0);
      visibility: hidden;
  }

  50% {
      transform: translate3d(200vw, 0, 0);
      visibility: hidden;
  }

  51% {
      transform: translate3d(200vw, 0, 0);
      visibility: visible;
  }

  100% {
      transform: translate3d(0, 0, 0);
  }
}

.ticker-page {
  animation: ticker 40s linear infinite;
}

@media (min-width: 768px) {
  .cloud-1 {
      animation: cloud 180s linear infinite;
  }

  .cloud-2 {
      animation: cloud 240s linear infinite;
  }

  .cloud-3 {
      animation: cloud 300s linear infinite;
  }
}